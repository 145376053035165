export function ShiftKey() {
  return (
    <svg
      width="84"
      height="64"
      viewBox="0 0 168 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shift key</title>
      <path
        d="M24.2727 91.9091H32.0454V105H46.7727V91.9091H54.5454L39.4091 76.7727L24.2727 91.9091ZM30.0128 89.608L39.4091 80.2117L48.8054 89.608H44.2159V102.699H34.6023V89.608H30.0128Z"
        fill="currentColor"
      />
      <rect
        x="1"
        y="1"
        width="165"
        height="126"
        rx="15"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export function OptionKey() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Option key</title>
      <path
        d="M35.7557 102.295C39.5966 102.295 42.1819 99.3778 42.1819 94.983C42.1819 90.5511 39.5966 87.6335 35.7557 87.6335C31.9148 87.6335 29.3296 90.5511 29.3296 94.983C29.3296 99.3778 31.9148 102.295 35.7557 102.295ZM35.7557 100.338C32.8381 100.338 31.5086 97.8267 31.5086 94.983C31.5086 92.1392 32.8381 89.5909 35.7557 89.5909C38.6733 89.5909 40.0029 92.1392 40.0029 94.983C40.0029 97.8267 38.6733 100.338 35.7557 100.338Z"
        fill="currentColor"
      />
      <path
        d="M45.508 107.318H47.687V99.821H47.8717C48.3518 100.597 49.2751 102.295 52.008 102.295C55.5535 102.295 58.0279 99.4517 58.0279 94.946C58.0279 90.4773 55.5535 87.6335 51.9711 87.6335C49.2012 87.6335 48.3518 89.3324 47.8717 90.071H47.6131V87.8182H45.508V107.318ZM47.6501 94.9091C47.6501 91.733 49.0535 89.5909 51.7126 89.5909C54.4825 89.5909 55.8489 91.9176 55.8489 94.9091C55.8489 97.9375 54.4455 100.338 51.7126 100.338C49.0904 100.338 47.6501 98.1222 47.6501 94.9091Z"
        fill="currentColor"
      />
      <path
        d="M67.5194 87.8182H64.491V84.4205H62.312V87.8182H60.17V89.6648H62.312V98.5284C62.312 101.003 64.3063 102.185 66.1529 102.185C66.9654 102.185 67.4825 102.037 67.7779 101.926L67.3347 99.9688C67.1501 100.006 66.8546 100.08 66.3745 100.08C65.4143 100.08 64.491 99.7841 64.491 97.9375V89.6648H67.5194V87.8182Z"
        fill="currentColor"
      />
      <path
        d="M70.7971 102H72.9761V87.8182H70.7971V102ZM71.9051 85.4545C72.7545 85.4545 73.4562 84.7898 73.4562 83.9773C73.4562 83.1648 72.7545 82.5 71.9051 82.5C71.0556 82.5 70.3539 83.1648 70.3539 83.9773C70.3539 84.7898 71.0556 85.4545 71.9051 85.4545Z"
        fill="currentColor"
      />
      <path
        d="M82.7284 102.295C86.5693 102.295 89.1545 99.3778 89.1545 94.983C89.1545 90.5511 86.5693 87.6335 82.7284 87.6335C78.8875 87.6335 76.3022 90.5511 76.3022 94.983C76.3022 99.3778 78.8875 102.295 82.7284 102.295ZM82.7284 100.338C79.8108 100.338 78.4812 97.8267 78.4812 94.983C78.4812 92.1392 79.8108 89.5909 82.7284 89.5909C85.646 89.5909 86.9755 92.1392 86.9755 94.983C86.9755 97.8267 85.646 100.338 82.7284 100.338Z"
        fill="currentColor"
      />
      <path
        d="M94.6597 93.4688C94.6597 90.9943 96.1923 89.5909 98.279 89.5909C100.301 89.5909 101.529 90.9112 101.529 93.1364V102H103.708V92.9886C103.708 89.3693 101.778 87.6335 98.9068 87.6335C96.7648 87.6335 95.4352 88.5938 94.7705 90.0341H94.5858V87.8182H92.4807V102H94.6597V93.4688Z"
        fill="currentColor"
      />
      <path
        d="M76 21.8125H84.3864L96.1989 45.1818H106.273V42.3693H97.8864L86.0739 19H76V21.8125ZM94.0256 19V21.8125H106.273V19H94.0256Z"
        fill="currentColor"
      />
      <rect
        x="1"
        y="1"
        width="126"
        height="126"
        rx="15"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export function MinusKey() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Minus key</title>
      <path d="M72.3636 39H56V41.8125H72.3636V39Z" fill="currentColor" />
      <path
        d="M70.0625 85.3693H58.6079V88.1818H70.0625V85.3693Z"
        fill="currentColor"
      />
      <rect
        x="1"
        y="1"
        width="126"
        height="126"
        rx="15"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export function ApostropheKey() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Apostrophe key</title>
      <path
        d="M33.7386 84H36.6023V81.6477C36.6023 79.7557 37.5227 78.1193 38.5966 76.5852L36.9602 75.2557C34.9148 76.892 33.7386 79.6023 33.7386 81.6989V84Z"
        fill="currentColor"
      />
      <path
        d="M30.7386 40H33.6023V37.6477C33.6023 35.7557 34.5227 34.1193 35.5966 32.5852L33.9602 31.2557C31.9148 32.892 30.7386 35.6023 30.7386 37.6989V40ZM37.2841 40H40.1477V37.6477C40.1477 35.7557 41.0682 34.1193 42.142 32.5852L40.5057 31.2557C38.4602 32.892 37.2841 35.6023 37.2841 37.6989V40Z"
        fill="currentColor"
      />
      <path
        d="M87.1477 94.8182C87.7102 99.625 90.6761 103.358 96.9148 103.358C103 103.358 106.989 98.6534 106.989 91.4432V88.4261C106.989 81.2159 103 76.4602 96.608 76.4602C90.7273 76.4602 87.6591 80.0909 87.1477 85.1023H90.4205C90.9318 81.4716 92.7216 79.3239 96.608 79.3239C101.057 79.3239 103.716 83.0568 103.716 88.4773V88.8352H93.3864V91.6477H103.716C103.639 96.4418 101.338 100.545 96.9148 100.545C92.6705 100.545 90.983 98.5 90.4205 94.8182H87.1477Z"
        fill="currentColor"
      />
      <rect
        x="1"
        y="1"
        width="126"
        height="126"
        rx="15"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export function ColonKey() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Colon key</title>
      <path
        d="M36.2614 90.4205H32.9886L32.8352 91.8523C32.4645 95.3168 31.7358 98.7685 31.4034 100.392H33.7045C34.3182 98.6534 35.6222 94.7159 36.0568 91.8011L36.2614 90.4205ZM34.4205 79.6307C35.6861 79.6307 36.7216 78.5952 36.7216 77.3295C36.7216 76.0639 35.6861 75.0284 34.4205 75.0284C33.1548 75.0284 32.1193 76.0639 32.1193 77.3295C32.1193 78.5952 33.1548 79.6307 34.4205 79.6307Z"
        fill="currentColor"
      />
      <path
        d="M34.9602 50.2045C36.2258 50.2045 37.2614 49.169 37.2614 47.9034C37.2614 46.6378 36.2258 45.6023 34.9602 45.6023C33.6946 45.6023 32.6591 46.6378 32.6591 47.9034C32.6591 49.169 33.6946 50.2045 34.9602 50.2045ZM34.9602 35.6307C36.2258 35.6307 37.2614 34.5952 37.2614 33.3295C37.2614 32.0639 36.2258 31.0284 34.9602 31.0284C33.6946 31.0284 32.6591 32.0639 32.6591 33.3295C32.6591 34.5952 33.6946 35.6307 34.9602 35.6307Z"
        fill="currentColor"
      />
      <path
        d="M88.6648 76.8182H85.4943V88.4261H82.3239L75.5227 76.8182H71.6875L79.4602 89.3466L70.9716 103H74.5511L81.7102 91.392H85.4943V103H88.6648V91.392H92.4489L99.5568 103H103.187L94.6989 89.3977L102.523 76.8182H98.6875L91.8864 88.4261H88.6648V76.8182Z"
        fill="currentColor"
      />
      <rect
        x="1"
        y="1"
        width="126"
        height="126"
        rx="15"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
